import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Checkbox from "../../../../../components/checkbox"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CalloutBox from "../../../../../components/callout-box"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          courseId: "jahreszeiten",
          chapterId: "02-eigenes-gedicht",
          taskId: "jahreszeiten",
          event,
        })
        navigate("/kurse/jahreszeiten/02-eigenes-gedicht/schreibplan")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Die versteckte Ordnung des Gedichts" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Die versteckte Ordnung des Gedichts
          </Heading>
        </Stack>
        <Paragraph>
          Welche Form soll dein Gedicht bekommen? Nach welchen Prinzipien könnte
          es geordnet sein? Überlege dir ein Muster für die äußere Form deines
          Gedichts.
        </Paragraph>
        <Stack>
          <Stack space={6}>
            <CalloutBox>
              Du kannst zum Beispiel in den Anfangsbuchstaben der Zeilen ein
              Wort verstecken, wie bei einem Akrostichon. Dafür schreibst du die
              Buchstaben eines Wortes senkrecht untereinander. Anschließend wird
              jeder Buchstabe zum Anfangsbuchstaben einer Zeile deines Gedichts.
            </CalloutBox>
            <Checkbox id="order1" name="order1" value="true">
              Das will ich ausprobieren
            </Checkbox>
          </Stack>

          <Stack space={6}>
            <CalloutBox color="autumn">
              Du kannst mit Wiederholungen spielen. Beispielsweise mit Worten,
              Anfangsbuchstaben oder Anlauten, die immer wiederkehren.
            </CalloutBox>
            <Checkbox id="order2" name="order2" value="true">
              Das will ich ausprobieren
            </Checkbox>
          </Stack>

          <Stack space={6}>
            <CalloutBox color="winter">
              Oder mit Klängen, indem du beispielsweise nur Worte mit den
              Vokalen O und A verwendest.
            </CalloutBox>
            <Checkbox id="order3" name="order3" value="true">
              Das will ich ausprobieren
            </Checkbox>
          </Stack>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
